class Masteries {

  /**
   * @constructor
   */
  constructor(el) {
    this.el = el;
    this.isError = false;
    this.warScore = 0;
    this.jobScore = 0;
    
    const leafs = this.el.querySelectorAll('.mastery-leaf');
    this.warCheckboxes = leafs[0].querySelectorAll('input[type="checkbox"]');
    this.jobCheckboxes = leafs[1].querySelectorAll('input[type="checkbox"]');

    this._onSubmit = this.onSubmit.bind(this);
    this._onWarCheckboxChange = this.onWarCheckboxChange.bind(this);
    this._onJobCheckboxChange = this.onJobCheckboxChange.bind(this);
    
    this.form = this.warCheckboxes[0].form;
    this.form.addEventListener('submit', this._onSubmit)
    
    for (let i = 0; i < this.warCheckboxes.length; i++) {
      this.warCheckboxes[i].checked ? this.warScore = this.warScore + 1 : null
      this.warCheckboxes[i].addEventListener('change', this._onWarCheckboxChange)
    }

    for (let i = 0; i < this.jobCheckboxes.length; i++) {
      this.jobCheckboxes[i].checked ? this.jobScore = this.jobScore + 1 : null
      this.jobCheckboxes[i].addEventListener('change', this._onJobCheckboxChange)
    }

  }

  checkScore() {
    if (this.warScore <= 1 && this.jobScore <= 1 || this.jobScore === 2 && this.warScore === 0) {
      this.isError = false
      this.form.classList.remove('is-error')
    } else {
      this.isError = true
      this.form.classList.add('is-error')
    }
  }

  onWarCheckboxChange(e) {
    if (e.target.checked) {
      this.warScore = this.warScore + 1
    } else {
      this.warScore = this.warScore - 1
    }

    this.checkScore()
  }

  onJobCheckboxChange(e) {
    if (e.target.checked) {
      this.jobScore = this.jobScore + 1
    } else {
      this.jobScore = this.jobScore - 1
    }

    this.checkScore()
  }

  onSubmit(e) {
    if (this.isError) {
      e.preventDefault()
    }
  }

  destroy() {
    this.form.classList.remove('is-error')
    this.form.removeEventListener('submit', this._onSubmit)

    for (let i = 0; i < this.warCheckboxes.length; i++) {
      this.warCheckboxes[i].removeEventListener('change', this._onWarCheckboxChange)
      this.warCheckboxes[i].checked = false
    }

    for (let i = 0; i < this.jobCheckboxes.length; i++) {
      this.jobCheckboxes[i].removeEventListener('change', this._onJobCheckboxChange)
      this.jobCheckboxes[i].checked = false
    }

    this.isError = false;
    this.warScore = 0;
    this.jobScore = 0;
  }
}

export default Masteries;
