// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import Header from "partials/header.js"
import Faq from "partials/faq.js"
import Masteries from "partials/masteries.js"
import Swiper, { Pagination } from 'swiper';
import tippy from 'tippy.js';

let masteriesInstance = null
let headerInstance = null
let swiperInstance = null
let faqInstances = []
let tippyInstances = []

document.addEventListener("turbolinks:before-cache", function() {
  if (masteriesInstance) {
    masteriesInstance.destroy()
  }

  if (swiperInstance) {
    swiperInstance.destroy()
  }

  if (headerInstance) {
    headerInstance.destroy()
  }

  if (faqInstances.length > 0) {
    faqInstances[i].destroy()
  }

  if (tippyInstances.length > 0) {
    tippyInstances[i].destroy()
  }
});

document.addEventListener("turbolinks:load", function() {
  headerInstance = new Header();

  document.activeElement.blur();

  tippy('[data-tippy-content]', {
    allowHTML: true
  });

  const faqs = document.querySelectorAll('.js-faq')
  for (let i = 0; i < faqs.length; i++) {
    faqInstances[i] = new Faq(faqs[i])
  }

  swiperInstance = new Swiper('.swiper-mobile', {
    modules: [Pagination],
    spaceBetween: 30,
    pagination: {
      clickable: true,
      type: 'bullets',
      el: ".swiper-pagination",
    },
    enabled: true,
    breakpoints: {
      768: {
        enabled: false,
        spaceBetween: 0,
      }
    }
  });

  const masteries = document.querySelector('.js-mastery-root');
  if (masteries) {
    masteriesInstance = new Masteries(masteries);
  }
  
  const messages = document.documentElement.querySelectorAll('.flash-message')
  if (messages?.length > 0) {
    for (let index = 0; index < messages.length; index++) {
      const msg = messages[index];
      
      const btn = msg.querySelector('button')
      btn.addEventListener('click', (e) => {
        msg.parentElement.removeChild(msg)
      })

      if (msg && msg.classList.contains('flash-message-success')) {
        setTimeout(() => {
          msg.parentElement.removeChild(msg)
        }, 3500);
      }
    }
  }

  const forms = document.querySelectorAll('form.js-rcptch');
  for (let i = 0; i < forms.length; i++) {
    forms[i].addEventListener("submit", (event) => {
      const response = grecaptcha.getResponse();
      if (response.length === 0) {
          event.preventDefault();
          event.stopPropagation();
          alert("Veuillez verifier que vous n'êtes pas un robot.");
      }
    })
  }

  const dashboardToggler = document.documentElement.querySelector('.js-mobile-sidebar-trigger')
  const dashboardSidebar = document.documentElement.querySelector('.js-dashboard-sidebar')

  if (dashboardToggler) {
    dashboardToggler.addEventListener('click', (e) => {
      window.scrollTo(0, window.scrollY - 1)
      dashboardSidebar.classList.toggle('is-open')
    })
  }
})
