class Faq {

  /**
   * @constructor
   */
  constructor(el) {
    this.el = el;
    this.buttons = this.el.querySelectorAll('.faq__element-header > button')
    
    this._onItemClick = this.onItemClick.bind(this);

    for (let i = 0; i < this.buttons.length; i++) {
      this.buttons[i].addEventListener('click', this._onItemClick)
    }

  }

  onItemClick(e) {
    const wasOpen = e.currentTarget.getAttribute('aria-expanded') === 'true'
    this.closeAllElements()

    if (!wasOpen) {
      this.openElement(e.currentTarget)
    }
  }

  closeAllElements() {
    const allOpenItems = this.el.querySelectorAll('.is-open')

    for (let i = 0; i < this.buttons.length; i++) {
      this.buttons[i].setAttribute('aria-expanded', 'false')
    }

    for (let i = 0; i < allOpenItems.length; i++) {
      allOpenItems[i].classList.remove('is-open');
    }
  }

  openElement(button) {
    button.setAttribute('aria-expanded', 'true')
    const answer = button.getAttribute('aria-controls');
    const $answer = this.el.querySelector(`#${answer}`);
    $answer.classList.add('is-open');
  }

  closeElement(button) {
    button.setAttribute('aria-expanded', 'false')
    const answer = button.getAttribute('aria-controls');
    const $answer = this.el.querySelector(`#${answer}`);
    $answer.classList.remove('is-open');
  }

  destroy() {
    this.closeAllElements()

    for (let i = 0; i < this.buttons.length; i++) {
      this.buttons[i].removeEventListener('click', this._onItemClick)
    }
  }
}

export default Faq;
