class Header {

  /**
   * @constructor
   */
  constructor() {
    this.$body = document.body;
    this.$root = this.$body.querySelector('.site-header');
    this.$burger = this.$root.querySelector('.js-site-burger')
    this.$submenuTogglers = this.$root.querySelectorAll('.js-toggle-submenu');
    this.$enabledSubnav = null

    this.isSticky = false;
    this.scrollTop = 0;
    this.lastScrollTop = window.pageYOffset;

    // Events
    this._onScroll = this.onScroll.bind(this);
    this._onBurgerClick = this.onBurgerClick.bind(this);
    this._onSubmenuToggle = this.onSubmenuToggle.bind(this)

    // Scroll
    window.addEventListener('scroll', this._onScroll);
    this.onScroll()

    this.$burger.addEventListener('click', this._onBurgerClick)

    for (let i = 0; i < this.$submenuTogglers.length; i++) {
      this.$submenuTogglers[i].addEventListener('click', this._onSubmenuToggle.bind(this))
    }
  }

  /**
   * Reset header
   */
  resetScroll() {
    this.scrollTop = 0;

    this.$root.classList.remove('is-hidden');
    this.$root.classList.remove('is-sticky');
  }

  onBurgerClick() {
    this.$body.classList.toggle('menu-is-open');
  }

  onSubmenuToggle(e) {
    const parent = e.currentTarget.parentNode;
    
    if (parent.classList.contains('is-open')) {
      parent.classList.remove('is-open');
      this.$enabledSubnav = parent
    } else {
      parent.classList.add('is-open');
      this.$enabledSubnav = null
    }
  }

  /**
   * Watch scroll event
   */
  onScroll() {
    this.scrollTop = window.pageYOffset;

    if (this.scrollTop > this.lastScrollTop) {
      this.direction = 1;
    } else {
      if (this.scrollTop !== this.lastScrollTop) {
        this.direction = -1;
      }
    }

    if (this.direction === 1) {
      if (this.scrollTop > 158) {
        this.$root.classList.add('is-hidden');
      }
    } else {
      if (this.scrollTop > 100) {
        this.isSticky = true;

        this.$root.classList.remove('is-hidden');
        this.$root.classList.add('is-sticky');
      } else {
        this.$root.classList.remove('is-sticky');
      }

      if (this.scrollTop < 1) {
        this.isSticky = false;
      }
    }

    this.lastScrollTop = this.scrollTop;
  }

  destroy() {
    this.$body.classList.remove('menu-is-open');
    this.$root.classList.remove('is-hidden');
    this.$root.classList.remove('is-hidden');
    this.$root.classList.remove('is-sticky');
    window.removeEventListener('scroll', this._onScroll);
    this.$burger.removeEventListener('click', this._onBurgerClick)
  }
}

export default Header;
